@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap");

* {
  box-sizing: border-box;
}

html {
  /* line-height: 1.5; */
  font-family: AntennaRegular, Roboto, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 200;
}

body {
  font-family: "AntennaRegular";
  color: #00095b;
}

a {
  font-family: "AntennaRegular";
  color: #00095b;
}

button {
  font-family: "AntennaRegular";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  /* line-height: 1.25; */
  font-family: "AntennaRegular";
  font-weight: 600;
}

@font-face {
  font-family: "AntennaLight";
  src: local("AntennaLight"),
    url("Fonts/Antenna/FordAntenna-Light.eot") format("eot"),
    url("Fonts/Antenna/FordAntenna-Light.ttf") format("ttf"),
    url("Fonts/Antenna/FordAntenna-Light.woff") format("woff");
  font-weight: 100;
}

@font-face {
  font-family: "AntennaRegular";
  src: local("AntennaRegular"),
    url("Fonts/Antenna/FordAntenna-Regular.eot") format("eot"),
    url("Fonts/Antenna/FordAntenna-Regular.ttf") format("ttf"),
    url("Fonts/Antenna/FordAntenna-Regular.woff") format("woff");
  font-weight: 100;
}

@font-face {
  font-family: "AntennaRegularItalic";
  src: local("AntennaRegularItalic"),
    url("Fonts/Antenna/FordAntenna-RegularItalic.eot") format("eot"),
    url("Fonts/Antenna/FordAntenna-RegularItalic.ttf") format("ttf"),
    url("Fonts/Antenna/FordAntenna-RegularItalic.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "AntennaMedium";
  src: local("AntennaMedium"),
    url("Fonts/Antenna/FordAntenna-Medium.eot") format("eot"),
    url("Fonts/Antenna/FordAntenna-Medium.ttf") format("ttf"),
    url("Fonts/Antenna/FordAntenna-Medium.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "AntennaBold";
  src: local("AntennaBold"),
    url("Fonts/Antenna/FordAntenna-Bold.eot") format("eot"),
    url("Fonts/Antenna/FordAntenna-Bold.ttf") format("ttf"),
    url("Fonts/Antenna/FordAntenna-Bold.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "AntennaCondensedBold";
  src: local("AntennaCondensedBold"),
    url("Fonts/Antenna/FordAntennaCond-Bold.eot") format("eot"),
    url("Fonts/Antenna/FordAntennaCond-Bold.ttf") format("ttf"),
    url("Fonts/Antenna/FordAntennaCond-Bold.woff") format("woff");
  font-weight: 700;
}

/* @font-face {
  font-family: MaxEmoji;
  src: url("Fonts/Emoji/Brand-Live-Emoji.otf") format("opentype");
} */

.uppercase {
  text-transform: uppercase;
}
